import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { Layout, Container } from 'layouts';
import { TechnologyPartners, PortfolioSection, Testimonials, BrandsWorkedWithUs, Recognition, EngagementModels } from '@components';
import { SectionHeading, SectionDivider, SectionSubHeading } from '@components/Common';
import theme from '@config/theme';
import Anime from 'react-anime';
import backgroundTexture from '../../static/bg-texture.jpg';
import codeIcon from '@assets/code.svg';
import FitForBusiness from '@components/businessFit';
import servicesIcon from '@assets/services.svg';

const HeroWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CodeIcon = styled(codeIcon)`
  width: 52px;
  height: 52px;
`;

const ServicesIcon = styled(servicesIcon)`
  height: 75px;
  width: 75px;
`;

const ScrollIconContainer = styled.div`
  width: 40px;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  svg {
    width: 30px;
    path {
      stroke-dasharray: 50;
      stroke-dashoffset: 60;
      animation: dash 2s linear reverse infinite;
    }
    @keyframes dash {
      0% {
        stroke-dashoffset: 60;
        opacity: 0;
      }
      20% {
        stroke-dashoffset: 60;
        opacity: 1;
      }
      80% {
        stroke-dashoffset: 0;
        opacity: 1;
      }
      100% {
        stroke-dashoffset: 0;
        opacity: 0;
      }
    }
  }
`;

const HeroTextWrapper = styled.div`
  font-size: 100px;
  text-align: center;
  position: relative;
  font-family: ${(props) => props.theme.fontFamily.heading};
  width: 100%;
  line-height: 1;
  height: 240px;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  color: ${(props) => props.theme.colors.black.base};
  @media (max-width: ${theme.breakpoints.l}) {
    font-size: 72px;
  }
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 52px;
  }
  @media (max-width: ${theme.breakpoints.xl}) {
    font-size: 32px;
  }
  span {
    font-size: 120px;
    color: ${(props) => props.theme.colors.primary.base};
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    height: 100px;
    line-height: 1;
    @media (max-width: ${theme.breakpoints.l}) {
      font-size: 75px;
    }
    @media (max-width: ${theme.breakpoints.m}) {
      font-size: 55px;
    }
    @media (max-width: ${theme.breakpoints.xl}) {
      font-size: 35px;
    }
  }
`;

const HeroPara = styled.div`
  position: relative;
  margin-top: 20px;
  display: flex;
  width: 100%;
  height: calc(100vh - 80%);
  overflow: hidden;
  flex-direction: column;
  max-width: 600px;
  -webkit-font-smoothing: antialiased;
  p {
    font-size: 24px;
    font-family: ${theme.fontFamily.body};
    display: block;
    text-align: center;
    width: 100%;
    position: absolute;
    color: #000;
    @media (max-width: ${theme.breakpoints.m}) {
      font-size: 20px;
    }
    @media (max-width: ${theme.breakpoints.xl}) {
      font-size: 18px;
    }
  }
`;

const NumbersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.primary.base};

  h2 {
    margin: 0 0 10px 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    height: 100vh;
  }
`;

const CountMeta = styled.p`
  font-family: ${(props) => props.theme.fontFamily.heading};
  margin: 0;
  font-size: 25px;
  line-height: 1;
  font-weight: lighter;
`;
const backgroundAnimation = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
`;
const NumbersInnerWrapper = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${backgroundTexture});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px 0px;
  background-repeat: repeat-y;
  animation: ${backgroundAnimation} 20s linear infinite alternate;
  max-width: 100%;
  ${CountMeta} {
    font-size: 25px;
    line-height: 1;
    font-weight: lighter;

    @media (min-width: ${(props) => props.theme.breakpoints.m}) {
      font-size: 30px;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    h1 {
      font-size: 40px;
    }
  }
`;

const ClientsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-bottom: 20px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  position: relative;
  &:before {
    content: '';
    background: ${(props) => props.theme.colors.black.light};
    display: block;
    height: 2px;
    width: 400px;
    position: absolute;
    top: 0;
    left: calc(50% - 200px);
  }
`;

const StartupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: flex-end;
  flex: 0.5;
  padding: 30px;
  @media (max-width: ${theme.breakpoints.m}) {
    padding: 20px;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    padding: 15px;
  }
  border-right: 2px solid ${(props) => props.theme.colors.black.light};
}

`;

const UsersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
  flex: 0.5;
  padding: 30px;
  @media (max-width: ${theme.breakpoints.m}) {
    padding: 20px;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    padding: 15px;
  }
`;

const CountWrapper = styled.div`
  font-size: ${(props) => (props.size == 'big' ? '200px' : '120px')};
  font-family: ${(props) => props.theme.fontFamily.heading};
  color: ${(props) => props.theme.colors.black.base};
  line-height: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${theme.breakpoints.l}) {
    font-size: ${(props) => (props.size == 'big' ? '180px' : '100px')};
  }
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: ${(props) => (props.size == 'big' ? '80px' : '50px')};
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: ${(props) => (props.size == 'big' ? '60px' : '40px')};
  }
  span {
    font-size: 50px;
    @media (max-width: ${theme.breakpoints.m}) {
      font-size: 30px;
    }
  }
`;

const ServicesSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.colors.white.base};
  padding: 2rem 1.5rem;
`;

const ServiceWrapper = styled(Container)`
  display: flex;
  max-width: 1200px;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.base};
  padding: 30px 0;
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    flex-direction: column;
    align-items: start;
    ul {
      margin-left: 5px;
    }
  }
`;

const ServiceSectionSubHeading = styled(SectionSubHeading)`
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:after {
    content: ' ';
    width: 50%;
    height: 2px;
    background: ${(props) => props.theme.colors.primary.base};
    margin: 20px;
  }
`;

const ServiceHeadingWrapper = styled.div`
  display: flex;
  flex: 0.4;
  flex-direction: column;
  @media (max-width: ${theme.breakpoints.s}) {
    flex: 0 0 100%;
  }
`;

const ServiceDetailsWrapper = styled.div`
  display: flex;
  flex: 0.6;
  @media (max-width: ${theme.breakpoints.s}) {
    flex: 0 0 100%;
    width: 100%;
  }
`;

const ServicesListFlex = styled.div`
  display: flex;
  flex: 0.5;
`;

const ServicesList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
`;

const ServiceListItem = styled.li`
  display: flex;
  font-weight: 600;
  position: relative;
  padding: 0 0 0 20px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 8px;
    height: 8px;
    background-color: ${(props) => props.theme.colors.primary.base};
    border-radius: 50%;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 16px;
  }
`;

const ServiceListItemLink = styled(Link)`
  font-weight: 600;
  color: ${(props) => props.theme.colors.black.base};

  &:hover {
    color: ${(props) => props.theme.colors.black.base};
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 16px;
  }
`;

const ServiceHeading = styled.h2`
  font-size: 60px;
  margin-bottom: 10px;
  @media (max-width: ${theme.breakpoints.l}) {
    font-size: 42px;
  }
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 34px;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 26px;
  }
`;

const ServiceMeta = styled.p`
  font-size: 18px;
  margin-right: 20px;
  text-align: left;
  @media (max-width: ${theme.breakpoints.l}) {
    margin-right: 0px;
    text-align: left;
  }
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 16px;
    text-align: left;
  }
`;

const SectionMeta = styled.p`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 18px;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 14px;
  }
`;

const TechnologyPartnersSection = styled.section`
  background: ${(props) => props.theme.colors.white.lightest};
`;

class Index extends Component {
  render() {
    return (
      <Layout>
        <Helmet title={'Home Page'} />
        <HeroWrapper>
          <div>
            <CodeIcon />
          </div>
          <HeroTextWrapper>
            {'Rethink'}{' '}
            <Anime
              delay={(el, index) => index * 5500}
              keyframes={[
                { translateY: 40, opacity: 0, duration: 0 },
                { translateY: 0, opacity: 1, duration: 1500 },
                { translateY: 0, opacity: 0, delay: 3500, duration: 500 },
              ]}
              easing={'easeOutQuart'}
              loop={true}
            >
              <span>Engineering Excellence!</span>
              <span>Cost Effectiveness!</span>
              <span>Proactive Leadership!</span>
              <span>Great UI/UX!</span>
              <span>Dependable Outsourcing!</span>
              <span>Uncompromised Quality!</span>
              <span>Timezone Issues!</span>
              <span>Robust Communication!</span>
            </Anime>
          </HeroTextWrapper>
          <HeroPara>
            <Anime
              delay={(el, index) => index * 5500}
              keyframes={[
                { translateY: 0, opacity: 0, duration: 0 },
                { translateY: 0, opacity: 1, duration: 1500, delay: 500 },
                { translateY: 0, opacity: 0, delay: 3000, duration: 500 },
              ]}
              easing={'easeOutQuart'}
              loop={true}
            >
              <p>
                Our mission is to help tech startup founders get off the ground by leveraging our <b>extensive experience</b> and{' '}
                <b>solid technology backing</b>.
              </p>
              <p>Compared to local or in-house talent, our model helps save costs when you need it most.</p>
              <p>We put our best product and project manager minds to work on your solution.</p>
              <p>We design aesthetically pleasing and functional User Interfaces.</p>
              <p>Ownership is important and is deeply ingrained within our culture.</p>
              <p>We always keep a CTO level tech leadership aligned with all projects.</p>
              <p>What's critical to you is critical for us, time is no longer a barrier.</p>
              <p>Our communication skills may pleasantly surprise you!</p>
            </Anime>
          </HeroPara>
          <ScrollIconContainer>
            <svg version="1.1" x="0px" y="0px" viewBox="-224.9 213.8 63.6 54.6" enableBackground="new -224.9 213.8 63.6 54.6" xmlSpace="preserve">
              <polygon fill="#202020" points="-206.7,213.8 -224.9,241.1 -206.7,268.4 -179.4,268.4 -161.3,241.1 -179.4,213.8 " />
              <path
                fill="none"
                stroke="#12EBAE"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M-193.1,253.1l0,-25.0"
              />
            </svg>
          </ScrollIconContainer>
        </HeroWrapper>

        <NumbersWrapper>
          <NumbersInnerWrapper>
            <SectionHeading>Let the numbers do the talking!</SectionHeading>
            <SectionMeta>
              We have created multiple success stories for startup founders across the globe.
              <br /> From helping founders with MVPs to handling for scale.
            </SectionMeta>
            <ClientsWrapper>
              <CountWrapper size="big">
                200<span>+</span>
              </CountWrapper>
              <CountMeta>Satisfied Clients</CountMeta>
            </ClientsWrapper>
            <FlexWrapper>
              <StartupsWrapper>
                <CountWrapper>
                  $50m<span>+</span>
                </CountWrapper>
                <CountMeta>Startup funding raised</CountMeta>
              </StartupsWrapper>
              <UsersWrapper>
                <CountWrapper>
                  100m<span>+</span>
                </CountWrapper>
                <CountMeta>Monthly active users</CountMeta>
              </UsersWrapper>
            </FlexWrapper>
          </NumbersInnerWrapper>
        </NumbersWrapper>

        <ServicesSection>
          <ServicesIcon />
          <SectionHeading>What we do?</SectionHeading>
          <ServiceSectionSubHeading>You name it, we will do it.</ServiceSectionSubHeading>
          <ServiceWrapper>
            <ServiceHeadingWrapper>
              <ServiceHeading>Technologies</ServiceHeading>
              <ServiceMeta>
                From basics to path-breaking technologies, we hack all of them with sheer precision and excellence. We work on varied platforms and
                new age technologies. our solutions are highly scalable and we promise seamless integrations
              </ServiceMeta>
            </ServiceHeadingWrapper>
            <ServiceDetailsWrapper>
              <ServicesListFlex>
                <ServicesList>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/technologies/java'}>Kotlin</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/technologies/swift-objectiveC'}>Swift</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/technologies/react-native'}>React Native</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/technologies/'}>Flutter</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/technologies/react'}>React</ServiceListItemLink>
                  </ServiceListItem>
                </ServicesList>
              </ServicesListFlex>
              <ServicesListFlex>
                <ServicesList>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/technologies/'}>AI/ML</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/technologies/'}>Python</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/technologies/'}>TensorFlow</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/technologies/angular'}>Angular</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/technologies/'}>Vue.js</ServiceListItemLink>
                  </ServiceListItem>
                </ServicesList>
              </ServicesListFlex>
            </ServiceDetailsWrapper>
          </ServiceWrapper>
          <ServiceWrapper>
            <ServiceHeadingWrapper>
              <ServiceHeading>Services</ServiceHeading>
              <ServiceMeta>
                We work on a wide ranges of digital services and offer full scale of highly innovative and out of the box solutions.
              </ServiceMeta>
            </ServiceHeadingWrapper>
            <ServiceDetailsWrapper>
              <ServicesListFlex>
                <ServicesList>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/services/android-app'}>Android App Development</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/services/'}>UI UX Design</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/services/web-development'}>Web Development</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/services/enterprise-solution'}>Enterprise Solutions</ServiceListItemLink>
                  </ServiceListItem>
                </ServicesList>
              </ServicesListFlex>
              <ServicesListFlex>
                <ServicesList>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/services/ios-development'}>IOS App Development</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/services/'}>Internet of things</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/services/cross-platform'}>Cross Platform</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/services/wearable-tech'}>Wearable</ServiceListItemLink>
                  </ServiceListItem>
                </ServicesList>
              </ServicesListFlex>
            </ServiceDetailsWrapper>
          </ServiceWrapper>
          <ServiceWrapper>
            <ServiceHeadingWrapper>
              <ServiceHeading>Industries</ServiceHeading>
              <ServiceMeta>
                There is phenomenal digital transformation and emergence of automation in all spheres of business. We have worked across a vast range
                of industries and cater to the finest of technical expertise required for your business needs.
              </ServiceMeta>
            </ServiceHeadingWrapper>
            <ServiceDetailsWrapper>
              <ServicesListFlex>
                <ServicesList>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/industries/health-care'}>Health Care</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/industries/social-media'}>Social Media</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/industries/travel'}>Travel</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/industries/on-demand'}>On Demand</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/industries/education'}>Education</ServiceListItemLink>
                  </ServiceListItem>
                </ServicesList>
              </ServicesListFlex>
              <ServicesListFlex>
                <ServicesList>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/industries/media-and-entertainment'}>Media</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/industries/event'}>Events</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/industries/real-estate'}>Real Estate</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/industries/automobile'}>AutoMobile</ServiceListItemLink>
                  </ServiceListItem>
                  <ServiceListItem>
                    <ServiceListItemLink to={'/industries/saas'}>SaaS</ServiceListItemLink>
                  </ServiceListItem>
                </ServicesList>
              </ServicesListFlex>
            </ServiceDetailsWrapper>
          </ServiceWrapper>
        </ServicesSection>
        <TechnologyPartnersSection>
          <TechnologyPartners />
        </TechnologyPartnersSection>
        <PortfolioSection />

        <BrandsWorkedWithUs noBackground={true} />

        {/* <SectionDivider /> */}

        <FitForBusiness />

        <Testimonials />

        <Recognition />
      </Layout>
    );
  }
}

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            excerpt: PropTypes.string,
            frontmatter: PropTypes.shape({
              cover: PropTypes.object.isRequired,
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
              date: PropTypes.string.isRequired,
              tags: PropTypes.array,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export const query = graphql`
  query {
    allMarkdownRemark(limit: 6, sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 75)
          frontmatter {
            title
            path
            tags
            date(formatString: "MM.DD.YYYY")
            cover {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 90, traceSVG: { color: "#2B2B2F" }) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
